/**
 * Check validity of the contact us and report ad forms and submit them.
 */
function validateContactUsForm (e) {
  e.preventDefault();

  const $form = document.getElementById('js-form-contact-us');
  const isValid = $form.checkValidity();

  if (isValid) {
    grecaptcha.ready(() => {
      grecaptcha.execute(window.google_recaptcha_site_key, { action: 'submitContactUsForm' }).then((token) => {
        $form.querySelector('input[name="g-recaptcha-response"]').value = token;
        $form.submit();
      });
    });
    return;
  }

  $form.reportValidity();
}
function validateReportAdForm (e) {
  e.preventDefault();

  const $form = document.getElementById('js-form-report-ad');
  const isValid = $form.checkValidity();

  if (isValid) {
    grecaptcha.ready(() => {
      grecaptcha.execute(window.google_recaptcha_site_key, { action: 'submitReportAdForm' }).then((token) => {
        $form.querySelector('input[name="g-recaptcha-response"]').value = token;
        $form.submit();
      });
    });
    return;
  }

  $form.reportValidity();
}

/**
 * Add event listeners.
 */
document.addEventListener('DOMContentLoaded', () => {
  const contactUsModal = document.getElementById('js-form-contact-us-modal');
  const contactUsSubmitButton = document.getElementById('js-form-contact-us-submit-button');

  contactUsModal.addEventListener('shown.bs.modal', () => {
    contactUsModal.classList.add('transition-ended');
  });
  contactUsModal.addEventListener('hide.bs.modal', () => {
    contactUsModal.classList.remove('transition-ended');
  });
  contactUsSubmitButton.addEventListener('click', validateContactUsForm);

  const reportAdModal = document.getElementById('js-form-report-ad-modal');
  const reportAdSubmitButton = document.getElementById('js-form-report-ad-submit-button');

  reportAdModal.addEventListener('shown.bs.modal', () => {
    reportAdModal.classList.add('transition-ended');
  });
  reportAdModal.addEventListener('hide.bs.modal', () => {
    reportAdModal.classList.remove('transition-ended');
  });
  reportAdSubmitButton.addEventListener('click', validateReportAdForm);
});
